
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import moment from "moment";
import getUTC from "@/function/getUTC";
import PageResponse from "@/dataSource/api/pagination-helper/lib/PageResponse";
import FilterModel from "../components/filter-model";
import GetTopWebLogsResponse from "@/dataSource/api/logger-micro-service/services/models/response/web-getter-service/GetTopWebLogsResponse";
import TopWebLogItem from "@/dataSource/api/logger-micro-service/services/models/response/web-getter-service/TopWebLogItem";
import HttpTopViewTable from "./http-top-view-table.vue";
import GetTopWebLogsRequest from "@/dataSource/api/logger-micro-service/services/models/request/web-getter-service/GetTopWebLogsRequest";
@Options({
  name: "HttpTopHeightView",
  components: {
    HttpTopViewTable,
  },
})
export default class HttpTopHeightView extends Vue {
  @Prop({ default: new FilterModel() }) filter: FilterModel;

  /*
    x = this.$api.TopWebLogsService.getTopFatRequestsAsync
    x = this.$api.TopWebLogsService.getTopFatResponsesAsync
    x = this.$api.TopWebLogsService.getTopLongRequestsAsync
  
  */
  //  xx = this.$api.TopWebLogsService.
  // x: GetTopWebLogsResponse;
  // y: TopWebLogItem
  // z: GetTopWebLogsRequest;
  // k: GetTopWebLogsResponse

  innerFilter: FilterModel = new FilterModel();

  itemsTopLongRequest: TopWebLogItem[] = [];
  pageTopLongRequest: PageResponse = null;

  // itemsTopReqVolume: GetTopWebLogsResponse = null; //топ request
  itemsTopFatRequests: TopWebLogItem[] = [];
  pageTopFatRequests: PageResponse = null;

  // itemsTopResp: GetTopWebLogsResponse = null; //топ response
  itemsTopFatResponses: TopWebLogItem[] = [];
  pageTopFatResponses: PageResponse = null;

  error1 = {
    isSuccess: true,
    errorMessage: "",
  };
  error2 = {
    isSuccess: true,
    errorMessage: "",
  };
  error3 = {
    isSuccess: true,
    errorMessage: "",
  };
  error4 = {
    isSuccess: true,
    errorMessage: "",
  };

  endpointsIdArr: number[] = [];
  size = 10;
  // page: PageRequest = { Take: 10, Skip: 0 };
  count = 0;
  skip = 0;

  noPagination = false;
  isLoading = false;

  m(value: Date) {
    return moment(value).format("DD.MM.YYYY hh:mm");
  }

  onFilterChange() {
    if (this.$refs.pagination as any) {
      (this.$refs.pagination as any).reset();
    }
    this.onclickFilterChange();
  }

  async onclickFilterChange() {
    await this.getItemsTopLongRequest(1);
    await this.getItemsTopFatResponses(1);
    await this.getItemsTopLongRequests(1);
    this.skip = 0;
    if (this.endpointsIdArr.length > 0) {
      try {
        // let res = await this.getItemsStat();
        // console.log("ress=", res);
      } catch (error) {
        this.$notification.error(error.ErrorMessage);
        console.log("error=", error);
        return;
      }
    }
  }

  trim(value: string) {
    let rez = value && value.trim() === "" ? null : value;
    return rez;
  }

  async getItemsTopLongRequest(page: number = 1) {
    let response: GetTopWebLogsResponse = null;
    this.isLoading = true;
    const innerFilter1: GetTopWebLogsRequest = {
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.filter.Ip,
      UserId: this.filter.UserId,
      SessionToken: this.trim(this.filter.SessionToken),
      // ConnectionId: this.filter.ConnectionId,
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      Page: { Skip: (page - 1) * this.size, Take: this.size },
    };
    let tmpFilter = getUTC(innerFilter1);

    try {
      response = await this.$api.TopWebLogsService.getTopLongRequestsAsync(tmpFilter);
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
      this.error1.isSuccess = false;
      this.error1.errorMessage = error;
      this.isLoading = false;
    }
    // this.itemsTopReqTime = response;
    this.itemsTopLongRequest = response.Items.map((item) => {
      return {
        AverageExecutingTime: 0,
        SummaryExecutingTime: 0,
        AverageResponsesSize: 0,
        AverageRequestsSize: 0,
        DateCreate: item.DateCreate,
        // ConnectionId: item.ConnectionId,
        RequestId: item.RequestId,
        RequestLength: item.RequestLength,
        SessionToken: item.SessionToken,
        // EndpointId: item.EndpointId,
        LevelId: item.LevelId,
        // Class: item.Class,
        Method: item.Method,
        ResponseLength: item.ResponseLength,
        ExecTimeMs: item.ExecTimeMs,
        UserId: item.UserId,
        Ip: item.Ip,
      };
    });

    this.pageTopLongRequest = response.Page;
    // this.addEnpoints(response.Items);
    this.isLoading = false;
  }

  async getItemsTopFatResponses(page: number = 1) {
    let response: GetTopWebLogsResponse = null;
    this.isLoading = true;
    const innerFilter1: GetTopWebLogsRequest = {
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.filter.Ip,
      UserId: this.filter.UserId,
      SessionToken: this.trim(this.filter.SessionToken),
      // ConnectionId: this.filter.ConnectionId,
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      Page: { Skip: (page - 1) * this.size, Take: this.size },
    };
    let tmpFilter = getUTC(innerFilter1);
    try {
      response = await this.$api.TopWebLogsService.getTopFatRequestsAsync(tmpFilter);
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
      this.error2.isSuccess = false;
      this.error2.errorMessage = error;
      this.isLoading = false;
    }
    // this.itemsTopReqVolume = response;
    this.itemsTopLongRequest = response.Items.map((item) => {
      return {
        AverageExecutingTime: 0,
        SummaryExecutingTime: 0,
        AverageResponsesSize: 0,
        AverageRequestsSize: 0,
        DateCreate: item.DateCreate,
        // ConnectionId: item.ConnectionId,
        RequestId: item.RequestId,
        RequestLength: item.RequestLength,
        SessionToken: item.SessionToken,
        // EndpointId: item.EndpointId,
        LevelId: item.LevelId,
        // Class: item.Class,
        Method: item.Method,
        ResponseLength: item.ResponseLength,
        ExecTimeMs: item.ExecTimeMs,
        UserId: item.UserId,
        Ip: item.Ip,
      };
    });

    this.pageTopLongRequest = response.Page;
    // this.addEnpoints(response.Items);
    this.isLoading = false;
  }

  // onChangePageTopResp(page: number) {
  //   this.getItemsTopResp(page);
  // }

  async getItemsTopLongRequests(page: number = 1) {
    let response: GetTopWebLogsResponse = null;
    this.isLoading = true;
    const innerFilter1: GetTopWebLogsRequest = {
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.filter.Ip,
      UserId: this.filter.UserId,
      SessionToken: this.trim(this.filter.SessionToken),
      // ConnectionId: this.filter.ConnectionId,
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      Page: { Skip: (page - 1) * this.size, Take: this.size },
    };
    let tmpFilter = getUTC(innerFilter1);
    try {
      response = await this.$api.TopWebLogsService.getTopFatResponsesAsync(tmpFilter);
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
      this.error3.isSuccess = false;
      this.error3.errorMessage = error;
      this.isLoading = false;
    }
    // this.itemsTopResp = response;
    this.itemsTopFatResponses = response.Items.map((item) => {
      return {
        AverageExecutingTime: 0,
        SummaryExecutingTime: 0,
        AverageResponsesSize: 0,
        AverageRequestsSize: 0,
        DateCreate: item.DateCreate,
        // ConnectionId: item.ConnectionId,
        RequestId: item.RequestId,
        RequestLength: item.RequestLength,
        SessionToken: item.SessionToken,
        // EndpointId: item.EndpointId,
        LevelId: item.LevelId,
        // Class: item.Class,
        Method: item.Method,
        ResponseLength: item.ResponseLength,
        ExecTimeMs: item.ExecTimeMs,
        UserId: item.UserId,
        Ip: item.Ip,
      };
    });

    this.pageTopFatResponses = response.Page;
    // this.addEnpoints(response.Items);
    this.isLoading = false;
  }

  //событие срабатывет при изменении пагинации при просмотре
  onPaginationChanged(page: number) {
    let skip = (page - 1) * this.size;
    if (this.skip === skip) {
      return;
    }
    this.skip = skip;
    // this.getItemsStat();
  }

  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }
}
